<template>
  <div class="container" style="align-items: center;overflow-y: scroll;">
    <div class="top">
      <img src="../assets/images/navi_back.png" alt="" class="back" @click="back">
    </div>
    <div class="group">
      <div class="row" @click="bindPhone">
        <img src="../assets/images/bind_phone.png" alt="" class="icon">
        <span class="name1">手机</span>
        <div class="expand"></div>
        <span v-if="mobile_bind" class="binded">{{ phone }}</span>
        <span v-else class="bind">立即绑定</span>
      </div>
      <div class="line"></div>
      <div class="row" @click="bindEmail">
        <img src="../assets/images/bind_email.png" alt="" class="icon">
        <span class="name1">邮箱</span>
        <div class="expand"></div>
        <span
          v-if="email && email.length > 0"
          class="binded">{{ email }}</span>
        <span v-else class="bind">立即绑定</span>
      </div>
    </div>
    <div class="group">
      <div class="row">
        <img src="../assets/images/bind_wechat.png" alt="" class="icon">
        <span class="name1">微信</span>
        <div class="expand"></div>
        <!-- <span v-if="mobile_bind" class="binded">{{phone}}</span> -->
        <span class="bind">立即绑定</span>
      </div>
      <div class="line"></div>
      <div class="row">
        <img src="../assets/images/bind_qq.png" alt="" class="icon">
        <span class="name1">QQ</span>
        <div class="expand"></div>
        <span class="bind">立即绑定</span>
      </div>
      <div class="line"></div>
      <div class="row">
        <img src="../assets/images/bind_weibo.png" alt="" class="icon">
        <span class="name1">微博</span>
        <div class="expand"></div>
        <span class="bind">立即绑定</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    msg: String
  },
  data() {
    return {
      email: '',
      mobile_bind: false,
      phone: ''
    }
  },
  mounted() {
    var _this = this
    this.$get(this.$api.userInfo).then(data => {
      _this.email = data.email;
      if (data.mobile_bind) {
        _this.mobile_bind = true;
      }
      _this.phone = data.mobile;
    })
  },
  methods: {
    back() {
      this.$router.push('/').catch(err=>{})
    },
    bindPhone() {
      if (this.mobile_bind) {
        return
      }
      this.$router.push({ path: '/bind_account', query: { type: 'mobile' } }).catch(err=>{})
    },
    bindEmail() {
      if (this.email && this.email.length > 0) {
        return
      }
      this.$router.push({ path: '/bind_account', query: { type: 'email' } }).catch(err=>{})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.top {
  height: 0.48rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  padding-left: 0.32rem;

  // padding-left: 0.24rem;
  .back {
    width: 0.24rem;
    height: 0.24rem;
    padding: 0.06rem;
  }
}

.group {
  width: 3.43rem;
  border-radius: 0.24rem;
  padding: 0 0.2rem;
  background-color: #2C2C2EFF;
  margin-bottom: 0.16rem;
  box-sizing: border-box;

  .row {
    align-items: center;
    height: 0.62rem;

    .icon {
      width: 0.24rem;
      height: 0.24rem;
    }

    .name1 {
      color: #fff;
      font-weight: bold;
      font-size: 0.16rem;
      line-height: 0.24rem;
      margin-left: 0.08rem;
      font-family: 'sans-serif';
    }

    .bind {
      font-size: 0.14rem;
      color: #FF9900FF;
    }

    .binded {
      font-size: 0.14rem;
      color: #74747AFF;
    }
  }

  .line {
    background-color: #404040FF;
    height: 0.01rem;
    width: 100%;
  }
}
</style>